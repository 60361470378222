import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { getLocationMeta } from '../helpers/main';
import { RU_DOMAIN } from '../locales';

const MetaTags = ({
  schema,
  title,
  path,
  description,
  contentType,
  published,
  updated,
  image,
}) => {
  const locationMeta = getLocationMeta();
  return (
    <Helmet>
      {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
      <html
        itemScope
        itemType={`http://schema.org/${schema}`}
      />
      <title>{`${title}${locationMeta}`}</title>
      <meta
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        property="og:type"
        content={contentType}
      />
      <meta charSet="UTF-8" />
      <meta name="theme-color" content="#000000" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="application-name" content="Артократия" />
      <meta name="apple-mobile-web-app-title" content="Артократия" />
      <meta name="msapplication-starturl" content="/" />
      <meta name="author" content="Артократия" />
      <meta property="og:site_name" content="Артократия" />
      {title && [
        <meta key={0} name="title" content={`${title}${locationMeta}`} />,
        <meta
          key={1}
          itemProp="name"
          content={`${title}${locationMeta}`}
        />,
        <meta
          key={2}
          name="twitter:title"
          content={`${title}${locationMeta}`}
        />,
        <meta
          key={3}
          property="og:title"
          content={`${title}${locationMeta}`}
        />,
      ]}
      {description && [
        <meta
          key={4}
          name="description"
          itemProp="description"
          content={`${description}${locationMeta}`}
        />,
        <meta
          key={5}
          name="twitter:description"
          content={`${description}${locationMeta}`}
        />,
        <meta
          key={6}
          property="og:description"
          content={`${description}${locationMeta}`}
        />,
      ]}
      <meta
        key={9}
        property="image"
        content={image || `${RU_DOMAIN}/images/main-bg.jpg`}
      />
      <meta
        key={10}
        property="og:image"
        content={image || `${RU_DOMAIN}/images/main-bg.jpg`}
      />
      <meta
        key={11}
        name="twitter:image:src"
        content={image || `${RU_DOMAIN}/images/main-bg.jpg`}
      />
      {path && [
        <meta
          key={7}
          name="url"
          content={path}
        />,
        <meta
          key={8}
          name="og:url"
          content={path}
        />,
      ]}
      {published && (
        <meta
          name="article:published_time"
          content={moment.unix(published).format('YYYY-MM-DD')}
        />
      )}
      {updated && (
        <meta
          name="article:modified_time"
          content={moment.unix(updated).format('YYYY-MM-DD')}
        />
      )}
    </Helmet>
  );
};

MetaTags.defaultProps = {
  schema: 'WebPage',
  contentType: 'website',
  published: null,
  updated: null,
  image: null,
  description: null,
  path: null,
  title: '',
};

MetaTags.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
  image: PropTypes.string,
  schema: PropTypes.string,
  contentType: PropTypes.string,
  published: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  updated: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
};

export default MetaTags;
